<template>
  <div>
    <div class="content-in__title">
      <div class="header__search mobil-none-d">
        <el-form ref="form" :model="filterForm" class="d-flex f-column">
          <el-row class="d-flex">
            <h1
              class="content-title mr-3 ml-2"
              :class="mode ? 'content__titleday' : 'content__titlenight'"
            >
              {{ $t("message.managerReport") }}
            </h1>
            <el-form-item class="mr-2" v-if="authUser.is_super_admin">
              <select-users
                :size="'mini'"
                :placeholder="$t('message.user')"
                :class="mode ? 'input__day' : 'input__night'"
                :id="filterForm.user_id"
                v-model="filterForm.user_id"
              >
              </select-users>
            </el-form-item>
            <el-form-item class="mr-2">
              <el-date-picker
                class="w-100"
                :class="mode ? 'filter__day' : 'filter__night'"
                v-model="filterForm.date_interval"
                type="daterange"
                :range-separator="$t('message.to')"
                :start-placeholder="$t('message.begin_date')"
                :end-placeholder="$t('message.end_date')"
                size="mini"
				format="yyyy-MM-dd"
				value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <select-projects
                :size="'mini'"
                :placeholder="$t('message.projects')"
                :class="mode ? 'input__day' : 'input__night'"
                :id="filterForm.project_id"
                v-model="filterForm.project_id"
              >
              </select-projects>
            </el-form-item>
			<el-form-item class="px-2">
              <el-checkbox-group
                v-model="filterForm.checkedModules"
                :class="mode ? 'input__day' : 'input__night'"
              >
                <el-checkbox
                  v-for="(module, index) in modules"
                  :label="module.value"
                  :key="'module-' + index"
                  >{{ $t("message." + module.label) }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-row>

          <el-row>
       
          </el-row>
        </el-form>
      </div>
	  	<div v-loading="loadingData">
			<el-row>
			<div class="d-flex f-between px-2">
			<h4>{{$t('message.user')}}: {{ list.name }}</h4>
			<h4>{{$t('message.calldate')}}: {{ list.date_now }}</h4>
			</div>

			<div class="px-2 d-flex f-between">
			<b> {{$t('message.total_amount') + ' ' + $t('message.deals')}}: {{ manager_info.total | formatMoney(2) }}</b>
			<b>{{$t('message.profit')}}: {{ manager_info.deal_profit | formatMoney(2) }}</b>
			<b>{{$t('message.profit_user')}}: {{ manager_info.manager_profit | formatMoney(2) }}</b>
			</div>
			</el-row>
			<el-row class="mt-4">
				<el-col class="mt-3 px-2" :span="24">
				<h3>{{ $t("message.clients") }}</h3>
					<div v-if="list.clients && list.clients.length > 0">
						<table class="table-my-code table-bordered p-2 bode r-left-none">
							<thead>
							<tr>
								<th class="w50p">{{$t('message.n')}}</th>
								<th>{{ $t('message.first_name') }}</th>
								<th>{{ $t('message.phone') }}</th>
								<th >
								{{ $t('message.company_y') }}
								</th>
								<th>
								{{ $t('message.avarage_volume') }}
								</th>
								<!-- <th style="width: 200px">{{ $t("message.download") }}</th> -->
							</tr>
							</thead>
							<tbody>
							<tr  v-for="(client, index) in list.clients" :key="'client-' + index">
								<td>
								{{ client.id }}
								</td>
								<td>
								{{ client.full_name }}
								</td>
								<td>
								{{ client.phone }}
								</td>
								<td >
								{{ client.company ? client.company.name : "" }}
								</td>
								<td>
								{{ client.avarage_volume }}
								</td>
							</tr>
							</tbody>
						</table>
					</div>
				</el-col>
				<el-col :span="24" class="mt-5 px-2">
					<div v-for="(client, index) in list.clients" :key="index">
						<h4 v-if="client.deals.length > 0" >{{$t('message.client')}} : {{ client.full_name }}</h4>
						<el-table 
							v-if="client.deals.length > 0" 
							:data="client.deals" 
							:summary-method="getSummaries"
							show-summary
							border
							class="w-100" 
						>
							<el-table-column width="100" prop="id" :label="$t('message.deal') + ' ' + $t('message.n')">
							</el-table-column>
							<el-table-column prop="name" :label="$t('message.name')">
							</el-table-column>
							<el-table-column :label="$t('message.money_amount')">
								<template slot-scope="item">
									{{item.row.money_amount | formatMoney(2)}}
								</template>
							</el-table-column>
							<el-table-column :label="$t('message.paid_money')">
								<template slot-scope="item">
									{{item.row.paid_money | formatMoney(2)}}
								</template>
							</el-table-column>
							<el-table-column :label="$t('message.debt')">
								<template slot-scope="item">
									{{(item.row.money_amount - item.row.paid_money) | formatMoney(2)}}
								</template>
							</el-table-column>
							<el-table-column :label="$t('message.profit')">
								<template slot-scope="item">
									{{item.row.deal_profit | formatMoney(2)}}
								</template>
							</el-table-column>
							<el-table-column :label="$t('message.profit_user')">
								<template slot-scope="item">
									{{item.row.manager_profit | formatMoney(2)}}
								</template>
							</el-table-column>
						</el-table>
						<h4 v-if="client.deals.length > 0" class="text-right">{{ $t('message.all') + ' ' + $t('message.debt')}} : {{client.all_debt | formatMoney(2)}}</h4>
					</div>
				</el-col>
			</el-row>
	  	</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SelectUsers from "@/components/inventory/select-users";
import SelectProjects from "@/components/inventory/select-projects";
import { formatMoney } from "@/filters/index";

export default {
  components: {
    SelectUsers,
    SelectProjects,
  },
  data() {
    return {
      filterForm: {},
      loadingData: false,
      modules: [
        { label: "client", value: "client" },
        { label: "deal", value: "deal" },
        { label: "recall", value: "recall" },
        { label: "visit_and_meetings", value: "visit_and_meetings" },
        { label: "task", value: "task" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      list: "managerReport/list",
      authUser: "auth/user",
      filter: "managerReport/filter",
      mode: "MODE",
    }),
    manager_info() {
      if (!_.isEmpty(this.list.clients)) {
        let total = 0,
          deal_profit = 0,
          manager_profit = 0;

        this.list.clients.forEach((client) => {
          total += _.sumBy(client.deals, (deal) => +deal.money_amount);
          deal_profit += _.sumBy(client.deals, (deal) => +deal.deal_profit);
          manager_profit += _.sumBy(
            client.deals,
            (deal) => +deal.manager_profit
          );
        });

        return {
          total: total,
          deal_profit: deal_profit,
          manager_profit: manager_profit,
        };
      }

      return {
        total: 0,
        deal_profit: 0,
        manager_profit: 0,
      };
    },
  },
  watch: {
    filterForm: {
      handler: async function (newVal) {
        if (newVal.user_id) {
			if (newVal.date_interval) {
				newVal.begin_date = newVal.date_interval[0];
				newVal.end_date = newVal.date_interval[1];
			}
			else {
				newVal.begin_date = '';
				newVal.end_date = '';
			}
          this.updateFilter(newVal);
          this.debouncedFetchData();
        }
		else this.$store.commit('managerReport/EMPTY_LIST');
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.filterForm = JSON.parse(JSON.stringify(this.filter));
    this.debouncedFetchData = _.debounce(this.fetchData, 500);
  },
  methods: {
    ...mapActions({
      	updateList: "managerReport/index",
      	updateFilter: "managerReport/updateFilter",
    }),

    fetchData() {
		const query = { ...this.filter };
		if (!this.loadingData) {
			this.loadingData = true;
			this.updateList(query)
			.then((res) => {
				this.loadingData = false;
				this.setClientData(this.list);
			})
			.catch((err) => {
				this.loadingData = false;
			});
		}
    },

	getSummaries(param){
		const { columns, data } = param;
		const sums = [];

		columns.forEach((column, index) => {
			if (index == 0){
				sums[index] = this.$t('message.total');
				return;
			}
			if (index == 2){
				sums[index] = formatMoney(data.reduce((acc, obj) => acc + obj.money_amount, 0), 2);
				return;
			}
			if (index == 3){
				sums[index] = formatMoney(data.reduce((acc, obj) => acc +  parseFloat(obj.paid_money), 0), 2);
				return;
			}
			if (index == 4){
				sums[index] = formatMoney(data.reduce((acc, obj) => acc + (obj.money_amount - parseFloat(obj.paid_money)), 0), 2);
				return;
			}
			if (index == 5){
				sums[index] = formatMoney(data.reduce((acc, obj) => acc + obj.deal_profit, 0), 2);
				return;
			}
			if (index == 6){
				sums[index] = formatMoney(data.reduce((acc, obj) => acc + obj.manager_profit, 0), 2);
				return;
			}
		});
		return sums;
	}
  },
};
</script>

<style>
</style>